//--------------------------------------------------------------------------------------------------
//# Imports

import React, { useEffect, useContext } from 'react';
// To be added back later
// import SmartContractContext from '../../scripts/SmartContractContext';
import Aos from "aos";
import "aos/dist/aos.css";

//import walletButton from '../../image/button_4x1.png'
//import connectWallet from '../../scripts/SmartContractOperator';

import logo from '../../image/logo.webp'

import twitter_icon from '../../image/icons/twitter.webp'
// import linkedin_icon from '../../image/icons/linkedin.webp'
import instagram_icon from '../../image/icons/instagram.webp'
import discord_icon from '../../image/icons/discord.webp'

import early_access_button from '../../image/get-early-access-button.webp'

import './navbar.css'











//--------------------------------------------------------------------------------------------------
//# Variables

const connect_on_load = false;

var mobile = false;
if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
  console.log("Mobile device detected");
  mobile = true;
};

const delay_gap = 200;


//AppStart
const Navbar = () => {

// To be added back later
// let { user_address, setAddress_Context } = useContext(SmartContractContext);
// let { user_token_ID, setTokenID_Context } = useContext(SmartContractContext);
// let { user_balance, setBalance_Context } = useContext(SmartContractContext);
// let { user_metadata, setMetadata_Context } = useContext(SmartContractContext);
// let { user_avatar_URI, setAvatarURI_Context } = useContext(SmartContractContext);









//--------------------------------------------------------------------------------------------------
//# Functions

useEffect(() => {
  Aos.init({ duration: 2000 });

  // Reset AOS attributes after animation finishes
  setTimeout(() => {
    const buttons = document.querySelectorAll('.navbarCenterButton, .navbarEarlyAccessButton');
    buttons.forEach(button => {
      button.addEventListener('mouseenter', () => {
        button.style.transform = 'scale(1.15)';
        button.style.transition = 'transform 0.5s ease-in-out';
      });

      button.addEventListener('mousedown', () => {
        button.style.transform = 'scale(1.3)';
        button.style.transition = 'transform 0.05s ease-in-out';
      });
  
      button.addEventListener('mouseleave', () => {
        button.style.transform = 'scale(1.0)';
        button.style.transition = 'transform 0.5s ease-in-out';
      });
    });
  }, 1000);  // Match to AOS duration
}, []);

function mouseOver(event) {
  let element = document.getElementById(event.target.id);
  element.style.transform = 'scale(1.20)';
}

function mouseLeave(event) {
  let element = document.getElementById(event.target.id);
  element.style.transform = 'scale(1.0)';
}

function handleClick(event) {
  let element_ID = event.target.id;
  if (element_ID === 'navbarWhitelistSignup') {
    window.open("https://evanon.earth/alchmbetasignup-siteref", "_blank", "noopener noreferrer");
  } else if (element_ID === 'navbarTechnology') {
    var scroll = document.getElementsByClassName('alchmizerOverview')
    window.scroll({ behavior: 'smooth', top: scroll[0].offsetTop + 0 })
  } else if (element_ID === 'navbarRoadmap') {
    var scroll = document.getElementsByClassName('roadmap')
    window.scroll({ behavior: 'smooth', top: scroll[0].offsetTop + 0 })
  } else if (element_ID === 'navbarTeam') {
    var scroll = document.getElementsByClassName('team')
    window.scroll({ behavior: 'smooth', top: scroll[0].offsetTop + 0 })
  } else if (element_ID === 'navbarFAQ') {
    var scroll = document.getElementsByClassName('faq')
    window.scroll({ behavior: 'smooth', top: scroll[0].offsetTop + 0 })
  };
};









//--------------------------------------------------------------------------------------------------
//# HTML

return (
  <div className='navbar'>
    <div className='navbarContainer'>
      <div className='navbarLeft'>
        <div id="navbarLogo" className='navbarLogo'>
          <a href={window.location['origin']}>
            <img data-aos="fade-down-right" className="navbarLogoImage" id="navbarLogoImage" src={logo} alt='Home' onMouseOver={mouseOver} onMouseLeave={mouseLeave}/>
          </a>
        </div>
      </div>
      <div className='navbarCenter'>
        <div id='navbarWhitelistSignup' className='navbarCenterButton' data-aos="fade-down-right" data-aos-delay={1 * delay_gap} onClick={handleClick}>
          Whitelist Signup
        </div>
        <div id='navbarTechnology' className='navbarCenterButton' data-aos="fade-down-right" data-aos-delay={2 * delay_gap} onClick={handleClick}>
          Technology
        </div>
        <div id='navbarRoadmap' className='navbarCenterButton' data-aos="fade-down" data-aos-delay={3 * delay_gap} onClick={handleClick}>
          Roadmap
        </div>
        <div id='navbarTeam' className='navbarCenterButton' data-aos="fade-down-left" data-aos-delay={4 * delay_gap} onClick={handleClick}>
          Team
        </div>
        <div id='navbarFAQ' className='navbarCenterButton' data-aos="fade-down-left" data-aos-delay={5 * delay_gap} onClick={handleClick}>
          FAQ
        </div>
      </div>
      <div className='navbarRight'>
        <div className='navbarSocialsContainer'>
          <a href='https://achlm.xyz/' target="_blank">
            <img className='navbarIcon discordIcon' id='linkedinIcon' data-aos="fade-down-left" src={discord_icon} onMouseOver={mouseOver} onMouseLeave={mouseLeave}/>
          </a>
          <a href='https://evanon.earth/alchminstagram-alchmref' target="_blank">
            <img className='navbarIcon instagramIcon' id='instagramIcon' data-aos="fade-down" src={instagram_icon} onMouseOver={mouseOver} onMouseLeave={mouseLeave}/>
          </a>
          <a href='https://evanon.earth/alchmx-alchmref' target="_blank">
            <img className='navbarIcon twitterIcon' id='twitterIcon' data-aos="fade-down-right" src={twitter_icon} onMouseOver={mouseOver} onMouseLeave={mouseLeave}/>
          </a>
        </div>
      </div>
    </div>
    <div className='navbarEarlyAccessContainer'>
      <img id='navbarEarlyAccessButton' className='navbarEarlyAccessButton' data-aos="fade-left" data-aos-delay="1000" src={early_access_button} onMouseOver={mouseOver} onMouseLeave={mouseLeave}/>
    </div>
  </div>
)
}
//AppEnd

export default Navbar