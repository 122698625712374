              // Alchm is a cutting-edge NFT campaign with a model unlike any other. Rather than relying on
              // random algorithms to generate outfits and expressions, Alchm uses generative machine learning
              // AI to create custom avatars specific to you and unique to your personal astrology. By holding
              // an Alchm NFT, you gain access to an ever-increasing array of premium tools for better understanding
              // the way the universe affects your present, future and past. Know your Alchemy.

              // Simply scroll to the top of this page (alchm.xyz) and fill in your birthday, birth time and
              // birth city and state. While only the birthday is mandatory, adding more information increases
              // the accuracy of the generative AI. Then, connect your web wallet (such as MetaMask),
              // and click "Mint". Own your Alchemy.

              // By entering your birth time and birth location, the positions and angles of the planets and stars
              // relative to you at the exact moment of your birth can be calculated using basic astrophysics. Then,
              // that information is used to determine your unique elemental dignities, tarot card affinities,
              // decan-specific effects, and spirit, matter, essence and substance effect multipliers. Finally,
              // these values are fed to our generative machine learning AI, which weighs all of this information
              // to create your Alchm Avatar. Be your Alchemy.

              // By minting your personal Alchm Avatar NFT, you gain access to all of the alchemical information
              // calculated by our alchemy analysis algorithm ("The Alchmizer"). On top of that, you can visit our
              // site every day to see even more information about how your specific alchemy is modified by that
              // day's arrangement of the planets, stars, constellations, and Moon phase. Advance your Alchemy.


import React, { useState } from 'react'

import { ReactComponent as PlusSVG } from '../../icons/Plus.svg'
import { ReactComponent as MinusSVG } from '../../icons/Minus.svg'

import './faq.css'

const FAQ = () => {
  const [what_is_alchm, toggle_what_is_alchm] = useState(false)
  const [what_is_unique, toggle_what_is_unique] = useState(false)
  const [what_is_app, toggle_what_is_app] = useState(false)
  const [what_is_avatar, toggle_what_is_avatar] = useState(false)
  const [what_is_archon, toggle_what_is_archon] = useState(false)
  const [what_are_tokens, toggle_what_are_tokens] = useState(false)
  const [astrology_knowledge, toggle_astrology_knowledge] = useState(false)
  const [how_does_alchmizer, toggle_how_does_alchmizer] = useState(false)
  const [how_does_privacy, toggle_how_does_privacy] = useState(false)
  const [does_sustainability, toggle_does_sustainability] = useState(false)
  const [how_up_to_date, toggle_how_up_to_date] = useState(false)
  const [how_contribute, toggle_how_contribute] = useState(false)
  const [FAQ_item_13, toggle_item_13] = useState(false)
  const [FAQ_item_14, toggle_item_14] = useState(false)
  const [FAQ_item_15, toggle_item_15] = useState(false)
  const [FAQ_item_16, toggle_item_16] = useState(false)
  const [FAQ_item_17, toggle_item_17] = useState(false)
  const [FAQ_item_18, toggle_item_18] = useState(false)
  const [FAQ_item_19, toggle_item_19] = useState(false)
  const [FAQ_item_20, toggle_item_20] = useState(false)

  function mouseOver(event) {
    let element = document.getElementById(event.target.id);
    if (event.target.id.includes('faqQuestion')) {
      element.style.boxShadow = '0 0 15px 5px rgba(255, 255, 0, 0.8)';  // Glow effect
      element.style.transition = 'box-shadow 0.2s ease-in-out';  // Smooth transition
    };
  };

  function mouseLeave(event) {
    let element = document.getElementById(event.target.id);
    if (event.target.id.includes('faqQuestion')) {
      element.style.boxShadow = 'none';  // Remove glow
      element.style.transition = 'box-shadow 0.2s ease-in-out';
    };
  };

  return (
    <div className='faq'>
      <div className='faqTitleContainer'>
        <div className='faqTitleText'>FAQ</div>
      </div>
      <div className='faqContainer'>
        {/* FAQ Item 1 */}
        <div className='faqItem' id='faqQuestion1' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={() => toggle_what_is_alchm(!what_is_alchm)}>
          <div className='faqItemLeft'>
            <div className='faqItemTitle'>What is Alchm?</div>
          </div>
          <div className='faqItemRight'>{what_is_alchm ? <MinusSVG /> : <PlusSVG />}</div>
        </div>
        {what_is_alchm && (
          <div className='faqItemAnswer'>
            <div className='faqItemAnswerText'>
            Alchm helps people learn about themselves through their personal astrology and alchemy. 
            Using a combination of astronomy, biology, chemistry, data science, AI, and blockchain,
            Alchm provides insights on an individual's lifestyle, diet, relationships, destiny and beyond.
            <br />
            <br />
            Read a detailed explanation in the <a className='faqLink' href='https://docs.alchm.xyz/whitepaper/what-is-alchm' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Alchm Docs</a>.
            </div>
          </div>
        )}
        {/* FAQ Item 2 */}
        <div className='faqItem' id='faqQuestion2' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={() => toggle_what_is_unique(!what_is_unique)}>
          <div className='faqItemLeft'>
            <div className='faqItemTitle'>What makes Alchm's personalized insights unique?</div>
          </div>
          <div className='faqItemRight'>{what_is_unique ? <MinusSVG /> : <PlusSVG />}</div>
        </div>
        {what_is_unique && (
          <div className='faqItemAnswer'>
            <div className='faqItemAnswerText'>
              Many platforms can provide information about food, exercise, mental health, human relationships and beyond. 
              These platforms require users to supply as much personal information as possible, which is then stored and 
              sold for profit.
              <br />
              <br />
              Alchm is different. Using just a birthday, The Alchmizer derives a colossal amount of personalized data about 
              a person using modern science and machine learning. As a result, Alchm seamlessly and instantly produces unique 
              and personalized insights unavailable on any other platform, and users never need to type in their gender, race, 
              nationality, nor any other personal data.
              <br />
              <br />
              Read a detailed explanation in the <a className='faqLink' href='https://docs.alchm.xyz/whitepaper/how-we-do-it' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Alchm Docs</a>.
            </div>
          </div>
        )}
        {/* FAQ Item 3 */}
        <div className='faqItem' id='faqQuestion3' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={() => toggle_what_is_app(!what_is_app)}>
          <div className='faqItemLeft'>
            <div className='faqItemTitle'>What is the Alchm App?</div>
          </div>
          <div className='faqItemRight'>{what_is_app ? <MinusSVG /> : <PlusSVG />}</div>
        </div>
        {what_is_app && (
          <div className='faqItemAnswer'>
            <div className='faqItemAnswerText'>
              The Alchm App is a mobile and web-friendly platform for accessing all of Alchm's tools and insights. Use it to 
              generate your Alchm Avatar, view your personalized insights and charts, collect tokens, explore the stars, and beyond.
              <br />
              <br />
              Read a detailed explanation in the <a className='faqLink' href='https://docs.alchm.xyz/alchm-technology/alchm-app' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Alchm Docs</a>.
            </div>
          </div>
        )}
        {/* FAQ Item 4 */}
        <div className='faqItem' id='faqQuestion4' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={() => toggle_what_is_avatar(!what_is_avatar)}>
          <div className='faqItemLeft'>
            <div className='faqItemTitle'>What is an Alchm Avatar?</div>
          </div>
          <div className='faqItemRight'>{what_is_avatar ? <MinusSVG /> : <PlusSVG />}</div>
        </div>
        {what_is_avatar && (
          <div className='faqItemAnswer'>
            <div className='faqItemAnswerText'>
              Alchm Avatars are the corporeal manifestations of an individual's astrological and alchemical qualities. 
              The physical and metaphysical features of one's Alchm Avatar represent the unique features of an individual 
              bestowed upon them by the planets, stars, and elements.
              <br />
              <br />
              Alchm Avatars are generated using The Alchmizer, which calculates an individual's astrological and alchemical 
              qualities, and then uses that data to generate a unique character.
              <br />
              <br />
              Read a detailed explanation in the <a className='faqLink' href='https://docs.alchm.xyz/alchm-technology/alchm-avatars' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Alchm Docs</a>.
            </div>
          </div>
        )}
        {/* FAQ Item 5 */}
        <div className='faqItem' id='faqQuestion5' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={() => toggle_what_is_archon(!what_is_archon)}>
          <div className='faqItemLeft'>
            <div className='faqItemTitle'>What are the Alchm Archons?</div>
          </div>
          <div className='faqItemRight'>{what_is_archon ? <MinusSVG /> : <PlusSVG />}</div>
        </div>
        {what_is_archon && (
          <div className='faqItemAnswer'>
            <div className='faqItemAnswerText'>
              Alchm Archons are a limited release of premium, hand-crafted Alchm Avatars. Each Alchm Archon is generated by 
              The Alchmizer using the date and time of a specific significant cosmic event. Its appearance is also determined 
              by The Alchmizer and customized to perfection by Alchm's artists.
              <br />
              <br />
              The finite supply of Alchm Archons will be awarded to Alchm's most devoted users and contributors. Just like with 
              Alchm Avatars, the bearer of an Alchm Archon authentically owns it, and may keep it and use it outside of the Alchm App.
              <br />
              <br />
              If you are interested in being one of the few owners of an Alchm Archon, sign up with your email as soon as possible: <a className='faqLink' href='https://docs.alchm.xyz/alchm-technology/alchm-archons' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Alchm Archon Signup</a>
              <br />
              <br />
              Read a detailed explanation in the <a className='faqLink' href='https://docs.alchm.xyz/alchm-technology/alchm-archons' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Alchm Docs</a>.
            </div>
          </div>
        )}
        {/* FAQ Item 6 */}
        <div className='faqItem' id='faqQuestion6' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={() => toggle_what_are_tokens(!what_are_tokens)}>
          <div className='faqItemLeft'>
            <div className='faqItemTitle'>What are $SPIRIT, $MATTER, $ESSENCE and $SUBSTANCE tokens?</div>
          </div>
          <div className='faqItemRight'>{what_are_tokens ? <MinusSVG /> : <PlusSVG />}</div>
        </div>
        {what_are_tokens && (
          <div className='faqItemAnswer'>
            <div className='faqItemAnswerText'>
              Spirit, Matter, Essence, and Substance are the four Alchemical Configurations, which describe the 
              different potential ways particles can be organized to form entities. $SPIRIT, $MATTER, $ESSENCE, 
              and $SUBSTANCE tokens are corporeal representations of these core alchemical values, similar to how 
              Alchm Avatars are corporeal representations of an individual's alchemical qualities.
              <br />
              <br />
              You can constantly collect all 4 tokens in the Alchm App by generating your Alchm Avatar, exploring your daily 
              alchemical insights, connecting with other Alchm users, playing games, solving puzzles, and beyond.
              <br />
              <br />
              Read a detailed explanation in the <a className='faqLink' href='https://docs.alchm.xyz/alchm-technology/alchemy-tokens' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Alchm Docs</a>.
            </div>
          </div>
        )}
        {/* FAQ Item 7 */}
        <div className='faqItem' id='faqQuestion7' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={() => toggle_astrology_knowledge(!astrology_knowledge)}>
          <div className='faqItemLeft'>
            <div className='faqItemTitle'>Do I need to know astrology to use Alchm?</div>
          </div>
          <div className='faqItemRight'>{astrology_knowledge ? <MinusSVG /> : <PlusSVG />}</div>
        </div>
        {astrology_knowledge && (
          <div className='faqItemAnswer'>
            <div className='faqItemAnswerText'>
              No! Alchm is the ideal place to learn about astrology if one is interested. All astrology-related features 
              and effects are automatically calculated by Alchm's software. You can explore, play, and earn at your leisure 
              while The Alchmizer does the heavy lifting for you.
              <br />
              <br />
              Read a detailed explanation in the <a className='faqLink' href='https://docs.alchm.xyz/astrology-and-alchemy/astrological-charts' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Alchm Docs</a>.
            </div>
          </div>
        )}
        {/* FAQ Item 8 */}
        <div className='faqItem' id='faqQuestion8' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={() => toggle_how_does_alchmizer(!how_does_alchmizer)}>
          <div className='faqItemLeft'>
            <div className='faqItemTitle'>How does The Alchmizer work?</div>
          </div>
          <div className='faqItemRight'>{how_does_alchmizer ? <MinusSVG /> : <PlusSVG />}</div>
        </div>
        {how_does_alchmizer && (
          <div className='faqItemAnswer'>
            <div className='faqItemAnswerText'>
              The Alchmizer uses an individual's birth date, birth time, and birth location to determine the exact 
              positions of the planets and constellations relative to the individual at the time of their birth. 
              Then, all astrologically significant relationships between the celestial bodies and the individual are 
              determined. Next, that data is used to calculate all of the individual's unique alchemical properties. Finally, 
              that data is visualized in the form of the individual's Alchm Avatar, as well as in charts and graphs.
              <br />
              <br />              
              Personalized insights and recommendations are generated daily by comparing the individual's astrological 
              and alchemical nature with the astrological and alchemcial properties of the present day, which are similarly 
              calculated by The Alchmizer.
              <br />
              <br />
              Read a detailed explanation in the <a className='faqLink' href='https://docs.alchm.xyz/alchm-technology/the-alchmizer' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Alchm Docs</a>.
            </div>
          </div>
        )}
        {/* FAQ Item 9 */}
        <div className='faqItem' id='faqQuestion9' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={() => toggle_how_does_privacy(!how_does_privacy)}>
          <div className='faqItemLeft'>
            <div className='faqItemTitle'>How does Alchm protect my privacy?</div>
          </div>
          <div className='faqItemRight'>{how_does_privacy ? <MinusSVG /> : <PlusSVG />}</div>
        </div>
        {how_does_privacy && (
          <div className='faqItemAnswer'>
            <div className='faqItemAnswerText'>
              All information given to Alchm, the Alchm App, and The Alchmizer are provably encrypted such that only the person 
              who entered the information can see it, unless they choose to share it. The same is true for all insights, 
              data, and charts generated by Alchm's software. This breakthrough in autonomy of information is achieved 
              using blockchain technology.
              <br />
              <br />
              Read a detailed explanation in the <a className='faqLink' href='https://docs.alchm.xyz/alchm-technology/you-own-your-data' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Alchm Docs</a>.
            </div>
          </div>
        )}
        {/* FAQ Item 10 */}
        <div className='faqItem' id='faqQuestion10' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={() => toggle_does_sustainability(!does_sustainability)}>
          <div className='faqItemLeft'>
            <div className='faqItemTitle'>Do Alchm, blockchain, and AI harm the environment?</div>
          </div>
          <div className='faqItemRight'>{does_sustainability ? <MinusSVG /> : <PlusSVG />}</div>
        </div>
        {does_sustainability && (
          <div className='faqItemAnswer'>
            <div className='faqItemAnswerText'>
              No.
              <br />
              <br />
              Blockchain and artificial intelligence are the biggest driving forces behind sustainable energy. In the 
              past, generating green energy from remote windmills and turbines was fruitless, because electricity diminishes 
              over distance. The only way to fund such sustainable innovations was if the source of the sustainable energy was 
              near a large city. Now, thanks to blockchain and artificial intelligence, sustainable technologies can be 
              implemented anywhere, and still be economically sustainable, too. This can be seen in developed countries such 
              as China, as well as developing countries like El Salvador, which are both heavily investing in sustainable 
              energy technology so that they can more effectively mine bitcoin.
              <br />
              <br />
              Blockchain and artificial intelligence technologies use large amounts of electricity. Similarly, hospitals, 
              banks, schools, office buildings, restaurants, vehicles, and traffic signs use large amounts of electricity. 
              However, the Alchm team believes that hospitals, schools, restaurants, vehicles, and traffic signs are 
              important, and should not be shut down despite their use of electricity. Humans should not and will not stop 
              using electricity.
              <br />
              <br />
              Read more in <a className='faqLink' href='https://evanon.earth/btcsustainability-alchmref' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>this fantastic article</a>.
            </div>
          </div>
        )}
        {/* FAQ Item 11 */}
        <div className='faqItem' id='faqQuestion11' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={() => toggle_how_up_to_date(!how_up_to_date)}>
          <div className='faqItemLeft'>
            <div className='faqItemTitle'>How do I stay up-to-date on Alchm?</div>
          </div>
          <div className='faqItemRight'>{how_up_to_date ? <MinusSVG /> : <PlusSVG />}</div>
        </div>
        {how_up_to_date && (
          <div className='faqItemAnswer'>
            <div className='faqItemAnswerText'>
              Sign up for product updates, event invitations, exclusive content, and early access to Alchm:
              <br />
              <a className='faqLink' href='https://evanon.earth/alchmbetasignup-siteref' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Signup for Updates</a>
              <br />
              <br />
              Follow @AlchmAI on social media for giveaways, AMAs, product previews and beyond:
              <br />
              <a style={{color: "var(--color-main)"}} href="https://evanon.earth/alchmx-alchmref" target="_blank">X</a> | <a style={{color: "var(--color-main)"}} href="https://evanon.earth/alchminstagram-alchmref" target="_blank">Instagram</a> | <a style={{color: "var(--color-main)"}} href="https://alchm.xyz/" target="_blank">Telegram (coming soon)</a> | <a style={{color: "var(--color-main)"}} href="https://alchm.xyz/" target="_blank">Discord (coming soon)</a>
            </div>
          </div>
        )}
        {/* FAQ Item 12 */}
        <div className='faqItem' id='faqQuestion12' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={() => toggle_how_contribute(!how_contribute)}>
          <div className='faqItemLeft'>
            <div className='faqItemTitle'>Alchm looks cool! How do I contribute?</div>
          </div>
          <div className='faqItemRight'>{how_contribute ? <MinusSVG /> : <PlusSVG />}</div>
        </div>
        {how_contribute && (
          <div className='faqItemAnswer'>
            <div className='faqItemAnswerText'>
              Thanks! Please, enjoy early access to our platform and participate in our beta launch!
              <br />
              <a className='faqLink' href='https://evanon.earth/alchmbetasignup-siteref' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Signup for Beta Access</a>
              <br />
              <br />
              Join us on social media and give us your feedback, ideas, and questions!
              <br />
              <a style={{color: "var(--color-main)"}} href="https://evanon.earth/alchmx-alchmref" target="_blank">X</a> | <a style={{color: "var(--color-main)"}} href="https://evanon.earth/alchminstagram-alchmref" target="_blank">Instagram</a> | <a style={{color: "var(--color-main)"}} href="https://alchm.xyz/" target="_blank">Telegram (coming soon)</a> | <a style={{color: "var(--color-main)"}} href="https://alchm.xyz/" target="_blank">Discord (coming soon)</a>
              <br />
              <br />
              Want to join the Alchm team? Send us an application using the link below:
              <br />
              <a className='faqLink' href='https://evanon.earth/alchmbetasignup-siteref' target="_blank" rel="noreferrer" style={{ color: "#F1FF62"}}>Work with Alchm</a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default FAQ
