//--------------------------------------------------------------------------------------------------
//# Imports

import React, {useEffect, useRef } from 'react';

import Astrologizer from "../../scripts/Astrologizer";

import './chartoverview.css';

import chart_background_image from '../../image/space-bg-square.png';
import planet_image from '../../image/planet-image.png';

import sun_full_image from '../../image/chart/planets/sun.png';
import moon_full_image from '../../image/chart/planets/moon.png';
import mercury_full_image from '../../image/chart/planets/mercury.png';
import venus_full_image from '../../image/chart/planets/venus.png';
import mars_full_image from '../../image/chart/planets/mars.png';
import jupiter_full_image from '../../image/chart/planets/jupiter.png';
import saturn_full_image from '../../image/chart/planets/saturn.png';
import uranus_full_image from '../../image/chart/planets/uranus.png';
import neptune_full_image from '../../image/chart/planets/neptune.png';
import pluto_full_image from '../../image/chart/planets/pluto.png';









//--------------------------------------------------------------------------------------------------
//# Variables

let canvas, context;

const defaults = {'color': '#bbbbbb',
                  'line_thickness': '5',
                  'width': 100
};

let chart_parameters = {'circle1': {'color': '',
                                    'width': ''},
                        'lane_spacing': .3};


const asset_info = {'planets': {'Sun': {'full_image': sun_full_image},
                                'Moon': {'full_image': moon_full_image},
                                'Mercury': {'full_image': mercury_full_image},
                                'Venus': {'full_image': venus_full_image},
                                'Mars': {'full_image': mars_full_image},
                                'Jupiter': {'full_image': jupiter_full_image},
                                'Saturn': {'full_image': saturn_full_image},
                                'Uranus': {'full_image': uranus_full_image},
                                'Neptune': {'full_image': neptune_full_image},
                                'Pluto': {'full_image': pluto_full_image}},
                    'signs': {'Leo': {'element': 'fire'},
                              'Cancer': {'element': 'water'},
                              'Gemini': {'element': 'air'},
                              'Taurus': {'element': 'earth'},
                              'Aries': {'element': 'fire'},
                              'Pisces': {'element': 'water'},
                              'Aquarius': {'element': 'air'},
                              'Capricorn': {'element': 'earth'},
                              'Sagittarius': {'element': 'fire'},
                              'Scorpio': {'element': 'water'},
                              'Libra': {'element': 'air'},
                              'Virgo': {'element': 'earth'}},
                    'elements': {'fire': {'color': '#ff0000'},
                                 'water': {'color': '#1e44ff'},
                                 'air': {'color': '#83ff1e'},
                                 'earth': {'color': '#ffe100'}}};








//--------------------------------------------------------------------------------------------------
//# Functions

//AppStart
const ChartOverview = () => {
  
  const canvasRef = useRef(null);

  useEffect(() => {
    drawChart();
  }, []);

  async function drawChart(birth_info) {
    await initiateChart();
    
    const horoscope = await Astrologizer.astrologize(birth_info);

    const planet_info = horoscope['horoscope_parameters']['planets'];
    let planet_count = 0;

    for (const planet in planet_info) {
      if (asset_info['planets'][planet]) {
        console.log(planet);
        chart_parameters[planet] = await drawImage(asset_info['planets'][planet]['full_image'], 
                                                             {'length': chart_parameters['circle1']['width'] * .5 * ((10 - (planet_count * chart_parameters['lane_spacing'])) / 10),
                                                              'angle': -((planet_info[planet]['angle'] / 360) * 2 * Math.PI) + ((2 * Math.PI) * (1 / 12)),
                                                              'width': canvas.width * .04});
        planet_count++;
      };
    };

    let sign_count = 0;
    for (const sign in asset_info['signs']) {
      chart_parameters[sign] = await drawArc({'length': 1/12,
                                              'color': asset_info['elements'][asset_info['signs'][sign]['element']]['color'],
                                              'angle': sign_count / 12,
                                              'radius': canvas.width * .45})
      sign_count++;
    };

    console.log('chart_parameters', chart_parameters);
  };

  async function initiateChart() {
    canvas = canvasRef.current;
    context = canvas.getContext('2d');

    // Set the canvas size to match the image
    const chart_background_image_element = document.getElementById('chartOverviewBackgroundImage');
    canvas.width = chart_background_image_element.offsetWidth;
    canvas.height = chart_background_image_element.offsetHeight;    

    context.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
    
    chart_parameters['circle1'] = await drawCircle({'width': canvas.width * .9});
    chart_parameters['circle2'] = await drawCircle({'width': canvas.width * .3});

    chart_parameters['line0'] = await drawLine({'width': canvas.width * .45,
                                                'angle': 0});
    chart_parameters['line1'] = await drawLine({'width': canvas.width * .45,
                                                'angle': -Math.PI * (1/6)});
    chart_parameters['line2'] = await drawLine({'width': canvas.width * .45,
                                                'angle': -Math.PI * (2/6)});
    chart_parameters['line3'] = await drawLine({'width': canvas.width * .45,
                                                'angle': -Math.PI * (3/6)});
    chart_parameters['line4'] = await drawLine({'width': canvas.width * .45,
                                                'angle': -Math.PI * (4/6)});
    chart_parameters['line5'] = await drawLine({'width': canvas.width * .45,
                                                'angle': -Math.PI * (5/6)});
    chart_parameters['line6'] = await drawLine({'width': canvas.width * .45,
                                                'angle': -Math.PI * (6/6)});
    chart_parameters['line7'] = await drawLine({'width': canvas.width * .45,
                                                'angle': -Math.PI * (7/6)});
    chart_parameters['line8'] = await drawLine({'width': canvas.width * .45,
                                                'angle': -Math.PI * (8/6)});
    chart_parameters['line9'] = await drawLine({'width': canvas.width * .45,
                                                'angle': -Math.PI * (9/6)});
    chart_parameters['line10'] = await drawLine({'width': canvas.width * .45,
                                                'angle': -Math.PI * (10/6)});
    chart_parameters['line11'] = await drawLine({'width': canvas.width * .45,
                                                'angle': -Math.PI * (11/6)});
                                                
    // chart_parameters['arc0'] = await drawArc({'sign': 'Leo',
    //                                           'length': 1/12,
    //                                           'angle': 0,
    //                                           'radius': chart_parameters['circle1']['width'] * .5,
    //                                           'color': '#000000'});

  };


  async function setDefaults(style_dict) {
    // Set default styles for missing styles
    if (!style_dict['width']) {
      style_dict['width'] = defaults['width'];
    };
    if (!style_dict['color']) {
      style_dict['color'] = defaults['color'];
    };
    if (!style_dict['line_thickness']) {
      style_dict['line_thickness'] = defaults['line_thickness'];
    };

    if (!style_dict['x'] && style_dict['x'] !== 0) {
      style_dict['x'] = canvas.width / 2;
    };
    if (!style_dict['y'] && style_dict['y'] !== 0) {
      style_dict['y'] = canvas.height / 2;
    };

    console.log('Updated style_dict:', style_dict);
    return(style_dict);
  };


  async function drawCircle(style_dict={}) {
    style_dict = await setDefaults(style_dict);

    if (!style_dict['height']) {
      style_dict['height'] = style_dict['width'];
    };

    // Draws the circle
    const center_X = canvas.width / 2;
    const center_Y = canvas.height / 2;
    style_dict['radius'] = Math.min(style_dict['width'], style_dict['height']) / 2;
    context.beginPath();
    context.arc(center_X, center_Y, style_dict['radius'], 0, 2 * Math.PI);
    context.strokeStyle = style_dict['color'];
    context.lineWidth = style_dict['line_thickness'];
    context.stroke();

    return(style_dict);
  };

  // Relevant style:
  // radius, length, angle
  async function drawArc(style_dict={}) {
    console.log('\nChartOverview.js >>> RUNNING drawArc()');
    style_dict = await setDefaults(style_dict);

    if (!style_dict['length']) {
      style_dict['length'] = style_dict['width'];
    };

    // Draws the circle
    const center_X = canvas.width / 2;
    const center_Y = canvas.height / 2;
    context.beginPath();
    context.arc(center_X, center_Y, style_dict['radius'], style_dict['angle'] * 2 * Math.PI, (style_dict['angle'] * 2 * Math.PI) + (style_dict['length'] * 2 * Math.PI));
    context.strokeStyle = style_dict['color'];
    context.lineWidth = style_dict['line_thickness'];
    context.stroke();

    return(style_dict);
  };

  
  async function drawLine(style_dict={}) {
    console.log('\nChartOverview.js >>> RUNNING drawLine()');
    style_dict = await setDefaults(style_dict);

    context.beginPath();
    context.moveTo(style_dict['x'], style_dict['y']);
    context.lineTo(style_dict['x'] + style_dict['width'] * Math.cos(style_dict['angle']), style_dict['y'] + style_dict['width'] * Math.sin(style_dict['angle']));
    context.strokeStyle = style_dict['color'];
    context.lineWidth = style_dict['line_thickness'];
    context.stroke();

    return(style_dict);
  };

  async function drawImage(image, style_dict={}) {
    console.log('\nChartOverview.js >>> RUNNING drawImage()');
    style_dict = await setDefaults(style_dict);

    if (!style_dict['height']) {
      style_dict['height'] = style_dict['width'];
    };

    // Uses angle to draw image based on circular graphing
    if ((style_dict['length'] || style_dict['length'] === 0) && (style_dict['angle'] || style_dict['angle'] === 0)) {
      console.log("Using angle");
      style_dict['x'] = (canvas.width / 2) + (style_dict['length'] * Math.cos(style_dict['angle']));
      style_dict['y'] = (canvas.height / 2) + (style_dict['length'] * Math.sin(style_dict['angle']));
      console.log("Updated x and y for circular graphing:", style_dict['x'], style_dict['y']);
    };

    const image_element = new Image();
    return new Promise((resolve, reject) => {
      image_element.onload = () => {
        console.log('Image loaded:', image_element.src);
  
        // Draw the image on the canvas
        context.drawImage(
          image_element,
          style_dict['x'] - style_dict['width'],
          style_dict['y'] - style_dict['height'],
          style_dict['width'] * 2,
          style_dict['height'] * 2
        );
  
        resolve(style_dict); // Resolve the promise after drawing
      };
  
      image_element.onerror = (error) => {
        console.error('Failed to load image:', image_element.src, error);
        reject(error); // Reject the promise on error
      };
  
      // Set the image source to start loading
      image_element.src = image;
    });
  };








//--------------------------------------------------------------------------------------------------
//# HTML
  return (
    <div className='chartoverview'>
      <div className='chartOverviewContainer'>
        <canvas className='chartOverviewOverlayCanvas' id='chartOverlayCanvas' ref={canvasRef}></canvas>
        <img data-aos="zoom-in" src={chart_background_image} alt='' className='chartOverviewImage' id='chartOverviewBackgroundImage' />
      </div>
    </div>
  )
}

export default ChartOverview