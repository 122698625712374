//--------------------------------------------------------------------------------------------------
//# Imports

import { Origin, Horoscope } from "../../node_modules/circular-natal-horoscope-js";

import locationCSV from "./allLocationsUSfirst.csv";

export default { astrologize }







//--------------------------------------------------------------------------------------------------
//# Variables

const default_birthdays = {'Evan': {'year': 1990,
                                    'month': 3, // 0 = January, 11 = December!
                                    'date': 20,
                                    'hour': 16,
                                    'minute': 20,
                                    'latitude': 40.7498,
                                    'longitude': -73.7976},
                           'Greg': {'year': 1991,
                                    'month': 5, // 0 = January, 11 = December!
                                    'date': 23,
                                    'hour': 10,
                                    'minute': 24,
                                    'latitude': 40.7498,
                                    'longitude': -73.7976}};









//--------------------------------------------------------------------------------------------------
//# Functions

async function generateBirthInfo(birth_info_type) {
    console.log('\nAstrologizer.js >>> RUNNING generateBirthInfo()');
    let birth_info = {};

    

    if (birth_info_type === 'Evan' || birth_info_type === 'Greg') {
        birth_info = default_birthdays[birth_info_type];
    } else {
        birthCoordinates = await fetchCoordinates(true);
        const dateObject = new Date();
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth();
        const date = dateObject.getDate();
        const hour = dateObject.getHours();
        const minute = dateObject.getMinutes();
        birth_info = {year: year,
                      month: month, // 0 = January, 11 = December!
                      date: date,
                      hour: hour,
                      minute: minute,
                      latitude: birthCoordinates["Latitude"],
                      longitude: birthCoordinates["Longitude"]};
    };

    console.log('Birth Info: ', birth_info);
    return(birth_info);
};


async function fetchCoordinates(birthCountry='', birthState='', birthCity='') {
  await fetch(locationCSV)
    .then(response => response.text())
    .then(text => locationCSVtoArray(text));
  // Without location, the highest population city in the user's current time zone is used
  if (!birthCountry || !birthState || !birthCity) {
    const dateObject = new Date();
    var UTCoffset = dateObject.toString().split('GMT')[1].split(' (')[0];
    console.log('User UTC Offset: ', UTCoffset);
    if (!(UTCoffset in populousLocationsByTimeZone)) {
      UTCoffset = UTCoffset[0] + UTCoffset[1] + UTCoffset[2] + '00';
    };
    birthCountry = populousLocationsByTimeZone[UTCoffset]['Country'];
    birthState = populousLocationsByTimeZone[UTCoffset]['State'];
    birthCity = populousLocationsByTimeZone[UTCoffset]['City'];
    console.log('User Location Guess: ', birthCountry + ', ' + birthState + ', ' + birthCity);
  };
  // Pulls the latitude and longitude from the coordinateDict_Lowercase dictionary
  if (birthCountry.toLowerCase() === 'united states') {
    birthCoordinates = coordinateDict_Lowercase[birthCountry.toLowerCase() + ', ' + birthState.toLowerCase() + ', ' + birthCity.toLowerCase()];
  } else {
    birthCoordinates = coordinateDict_Lowercase[birthCountry.toLowerCase() + ', ' + birthCity.toLowerCase()];
  }
  console.log('birthCoordinates', birthCoordinates);
  return birthCoordinates;
};


async function astrologize(birth_info) {
  console.log('\nAstrologizer.js >>> RUNNING astrologize()');

  if (!birth_info) {
    birth_info = await generateBirthInfo();
  };

  // Uses circular-natal-horoscope-js to generate astrology info
  const origin = new Origin(birth_info);
  const tropical_horoscope = new Horoscope({
    origin: new Origin(origin),
    houseSystem: "placidus",
    zodiac: "tropical",
    aspectPoints: ['bodies', 'points', 'angles'],
    aspectWithPoints: ['bodies', 'points', 'angles'],
    aspectTypes: ["major"],
    customOrbs: {
      conjunction: 10,
      opposition: 10,
      trine: 8,
      square: 7,
      sextile: 6,
      "semi-square": 1,
      "semi-sextile": 1,
    },
    language: 'en'
  });

  console.log('_____Tropical Outputs____');
  console.log("Sun Sign: ", tropical_horoscope.CelestialBodies['sun']['Sign']['label']);
  console.log("Celestial Bodies: ", tropical_horoscope.CelestialBodies);
  console.log("Aspects: ", tropical_horoscope.Aspects);
  console.log("Ascendant: ", tropical_horoscope.Ascendant);

  const sidereal_horoscope = new Horoscope({
    origin: new Origin(origin),
    houseSystem: "placidus",
    zodiac: "sidereal",
    aspectPoints: ['bodies', 'points', 'angles'],
    aspectWithPoints: ['bodies', 'points', 'angles'],
    aspectTypes: ["major"],
    customOrbs: {
      conjunction: 10,
      opposition: 10,
      trine: 8,
      square: 7,
      sextile: 6,
      "semi-square": 1,
      "semi-sextile": 1,
    },
    language: 'en'
  });

  console.log('_____Sidereal Outputs____');
  console.log("Sun Sign: ", sidereal_horoscope.CelestialBodies['sun']['Sign']['label']);
  console.log("Celestial Bodies: ", sidereal_horoscope.CelestialBodies);
  console.log("Aspects: ", sidereal_horoscope.Aspects);
  console.log("Ascendant: ", sidereal_horoscope.Ascendant);

  let horoscope_parameters = {'planets': {}};

  const planet_list = tropical_horoscope.CelestialBodies.all;

  // Planet info
  for (let i = 0; i < planet_list.length; i++) {
    horoscope_parameters['planets'][planet_list[i]['label']] = {'sign': planet_list[i]['Sign']['label'],
                                                                'angle': planet_list[i]['ChartPosition']['Ecliptic']['DecimalDegrees'],
                                                                'degree': planet_list[i]['ChartPosition']['Ecliptic']['DecimalDegrees'] % 30,
                                                                'house': planet_list[i]['House']};
  };

  console.log('horoscope_parameters', horoscope_parameters);

  //horoscope.CelestialBodies['all'].forEach(entry =>
  //  console.log(entry['label'], entry['Sign']['label'], entry['House']['label']))
  return({'horoscope_parameters': horoscope_parameters,
          'tropical': tropical_horoscope,
          'sidereal': sidereal_horoscope,
          'birth_info': birth_info});
};


const populousLocationsByTimeZone = {'-1100': {'Abbreviation': 'SST',
  'Country': 'Pago Pago',
  'State': '',
  'City': 'American Samoa'},
'-1000': {'Abbreviation': 'HST',
  'Country': 'United States',
  'State': 'Hawaii',
  'City': 'Honolulu'}, 
'-0900': {'Abbreviation': 'AKST',
  'Country': 'United States',
  'State': 'Alaska',
  'City': 'Anchorage'},
'-0800': {'Abbreviation': 'PST',
  'Country': 'United States',
  'State': 'California',
  'City': 'Los Angeles'}, 
'-0700': {'Abbreviation': 'MST',
  'Country': 'United States',
  'State': 'Arizona',
  'City': 'Phoenix'},
'-0600': {'Abbreviation': 'CST',
  'Country': 'United States',
  'State': 'Illinois',
  'City': 'Chicago'}, 
'-0500': {'Abbreviation': 'EST',
  'Country': 'United States',
  'State': 'New York',
  'City': 'New York'},
'-0400': {'Abbreviation': 'AST',
  'Country': 'Brazil',
  'State': '',
  'City': 'Manaus'}, 
'-0300': {'Abbreviation': 'NST',
  'Country': 'Canada',
  'State': '',
  'City': "St. John's"},
'-0200': {'Abbreviation': 'BRST',
  'Country': 'Brazil',
  'State': '',
  'City': 'Sao Paulo'}, 
'-0100': {'Abbreviation': 'EGT',
  'Country': 'Cabo Verde',
  'State': '',
  'City': 'Praia'},
'+0000': {'Abbreviation': 'UTC',
  'Country': 'United Kingdom',
  'State': '',
  'City': 'London'}, 
'+0100': {'Abbreviation': 'WAT',
  'Country': 'Nigeria',
  'State': '',
  'City': 'Lagos'},
'+0200': {'Abbreviation': 'CAT',
  'Country': 'Egypt',
  'State': '',
  'City': 'Cairo'}, 
'+0300': {'Abbreviation': 'MSK',
  'Country': 'Russia',
  'State': '',
  'City': 'Moscow'},
'+0400': {'Abbreviation': 'AZT',
  'Country': 'Azerbaijan',
  'State': '',
  'City': 'Baku'}, 
'+0500': {'Abbreviation': 'PKT',
  'Country': 'Pakistan',
  'State': '',
  'City': 'Karachi'},
'+0600': {'Abbreviation': 'BST',
  'Country': 'Bangladesh',
  'State': '',
  'City': 'Dhaka'}, 
'+0700': {'Abbreviation': 'WIB',
  'Country': 'Indonesia',
  'State': '',
  'City': 'Jakarta'},
'+0800': {'Abbreviation': 'HKT',
  'Country': 'China',
  'State': '',
  'City': 'Shanghai'}, 
'+0900': {'Abbreviation': 'JST',
  'Country': 'Japan',
  'State': '',
  'City': 'Tokyo'},
'+1000': {'Abbreviation': 'AET',
  'Country': 'Australia',
  'State': '',
  'City': 'Brisbane'}, 
'+1100': {'Abbreviation': 'AEDT',
  'Country': 'Australia',
  'State': '',
  'City': 'Sydney'},
'+1200': {'Abbreviation': 'NZST',
  'Country': 'New Zealand',
  'State': '',
  'City': 'Auckland'}};

var locationsArray;
var countryArray = [];
var countryArray_Lowercase = [];
var countryCityDict = {};
var stateArray = [];
var stateArray_Lowercase = [];
var stateCityDict = {};
var cityArray = [];
var cityArray_ALL = [];
var cityArray_ALL_Lowercase = [];
var coordinateDict = {};
var coordinateDict_Lowercase = {};

var birthHour, birthMinute, birthCountry, birthState, birthCity;
var birthCoordinates;

// (() => {
//   fetch(locationCSV)
//   .then(response => response.text())
//   .then(text => locationCSVtoArray(text));
// })();

function locationCSVtoArray(str, delimiter = ",") {
  console.log('\nAstrologizer.js >>> RUNNING locationCSVtoArray()');
  var countryList = {};
  var stateList = {};
  var cityList = {} ;
  if (countryList.length === 0 || countryArray.length === 0) {
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    var currentCountry = '';
    var currentState = '';
    locationsArray = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object, header, index) {
    object[header] = values[index];
    return object;
  }, {}); 
  if (currentCountry !== el['Country']) {
  if (currentCountry !== '') {
  countryArray.push(currentCountry);
  countryArray_Lowercase.push(currentCountry.toLowerCase());
  }
  if (currentCountry !== 'United States' && currentCountry !== '') {
  countryCityDict[currentCountry.toLowerCase()] = cityArray;
  } else if (currentCountry === 'United States') {
  stateArray.push(currentState);
  stateArray_Lowercase.push(currentState.toLowerCase());
  stateCityDict[currentState.toLowerCase()] = cityArray;
  }
  currentCountry = el['Country'];
  cityArray = [];
  }
  if (currentCountry === 'United States') {
  if (currentState !== el['State']) {
  if (currentState !== '') {
  stateArray.push(currentState);
  stateArray_Lowercase.push(currentState.toLowerCase());
  stateCityDict[currentState.toLowerCase()] = cityArray;
  }
  cityArray = [];
  currentState = el['State'];
  }
  }
  if (currentCountry !== '') {
  if (currentCountry === 'United States') {
  coordinateDict[currentCountry + ', ' + currentState + ', ' + el['City']] = {'Latitude': el['Latitude'], 
                                          'Longitude': el['Longitude']}
  coordinateDict_Lowercase[currentCountry.toLowerCase() + ', ' + currentState.toLowerCase() + ', ' + el['City'].toLowerCase()] = {'Latitude': el['Latitude'], 
                                                                                              'Longitude': el['Longitude']}                                                                                        
  } else {
  coordinateDict[currentCountry + ', ' + el['City']] = {'Latitude': el['Latitude'], 
                    'Longitude': el['Longitude']}
  coordinateDict_Lowercase[currentCountry.toLowerCase() + ', ' + el['City'].toLowerCase()] = {'Latitude': el['Latitude'], 
                                                          'Longitude': el['Longitude']}
  }
  }
  if (el['City'] !== undefined) {
  cityArray.push(el['City']);
  cityArray_ALL.push(el['City']);
  cityArray_ALL_Lowercase.push(el['City'].toLowerCase());
  }
  return el;
  });
  if (countryList.length === 0) {
  countryArray.forEach(item => {
  let option = document.createElement('option');
  option.value = item;   
  countryList.appendChild(option);
  });
  }
  if (stateList.length === 0) {
  stateArray.forEach(item => {
  let option = document.createElement('option');
  option.value = item;   
  stateList.appendChild(option);
  });
  }
  if (cityList.length === 0) {
  cityArray_ALL.forEach(item => {
  let option = document.createElement('option');
  option.value = item;   
  cityList.appendChild(option);
  });
  }
  console.log("Country Array Length: ", countryArray.length);
  console.log("coordinateDict_Lowercase", coordinateDict_Lowercase);
  return locationsArray;
}
}
