import React from 'react'

import overview_image from '../../image/overview-image.webp'

import './overview.css'




const delay_gap = 300;



const Overview = () => {

  function mouseOver(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.20)';
  }
  
  function mouseLeave(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.0)';
  }

  return (
    <div className='overview'>
      <div className='overviewLeftContainer'>
        <div className='overviewImageContainer'>
          <img data-aos="flip-left" src={overview_image} alt='' className='overviewImage' />
        </div>
      </div>
      <div className='overviewRightContainer'>
        <div className='overviewTextContainer'>
          <div className='overviewText overviewTitle overviewTextTop' data-aos="fade-down" data-aos-delay={1 * delay_gap}>Realize yourself with <span style={{ color: 'var(--color-main)' }}>astrology & AI</span>.</div>
          <div className='overviewText overviewTitle' data-aos="fade-down-left" data-aos-delay={2 * delay_gap}>Explore the stars & <span style={{ color: 'var(--color-main)' }}>earn rewards.</span></div>
          <div className='overviewText overviewTitle' data-aos="fade-left" data-aos-delay={3 * delay_gap}>Forge your unique <span style={{ color: 'var(--color-main)' }}>Alchm Avatar.</span></div>
          <div  className='overviewText overviewTitle' data-aos="fade-up-left" data-aos-delay={4 * delay_gap}>Own your data. Own your identity.<br /><span style={{ color: 'var(--color-main)' }}>Own your earnings.</span></div>
          <div className='overviewText overviewTitle overviewTextBottom' data-aos="fade-up" data-aos-delay={5 * delay_gap}><span style={{ color: 'var(--color-main)' }}>Discover your Alchemy.</span></div>
        </div>
      </div>
    </div>
  )
}

export default Overview
