import React from 'react'

import avatarOverview_image from '../../image/avataroverview-image.webp'

import './avataroverview.css'


const delay_gap = 300;

const AvatarOverview = () => {

  function mouseOver(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.20)';
  }
  
  function mouseLeave(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.0)';
  }

  return (
    <div className='avatarOverview'>
      <div className='avatarOverviewLeftContainer'>
        <div className='avatarOverviewTextContainer'>
          <div className='avatarOverviewText avatarOverviewTitle avatarOverviewTextTop' data-aos="fade-down-right" data-aos-delay={1 * delay_gap}>Forge Your Alchm Avatar</div>
          <div  className='avatarOverviewText' data-aos="fade-right" data-aos-delay={2 * delay_gap}>If your astral self came to life, what would they look like? What would they do?<br /><span style={{ color: 'var(--color-main)' }}>Who would they be?</span></div>
          <div className='avatarOverviewText  avatarOverviewTextBottom' data-aos="fade-up-right" data-aos-delay={3 * delay_gap}>Generate your personalized Alchm Avatar <br /> and <span style={{ color: 'var(--color-main)' }}>forge your destiny.</span></div>
        </div>
      </div>
      <div className='avatarOverviewRightContainer'>
        <div className='avatarOverviewImageContainer'>
          <img data-aos="flip-right" src={avatarOverview_image} alt='' className='avatarOverviewImage' />
        </div>
      </div>
    </div>
  )
}

export default AvatarOverview
