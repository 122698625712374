import React, { useEffect } from 'react'

import Banner from '../../components/banner/Banner'
import Overview from '../../components/overview/Overview'
import AvatarOverview from '../../components/avataroverview/AvatarOverview'
import TokenOverview from '../../components/tokenoverview/TokenOverview'
import AlchmizerOverview from '../../components/alchmizeroverview/AlchmizerOverview'
import Roadmap from '../../components/roadmap/Roadmap'
import Team from '../../components/team/Team'
import FAQ from '../../components/faq/FAQ'

import ChartOverview from '../../components/chartoverview/ChartOverview'

import Break_Signup from '../../components/break_signup/Break_Signup'
import Break_Astral from '../../components/break_astral/Break_Astral'
import Break_Commune from '../../components/break_commune/Break_Commune'
import Break_Discover from '../../components/break_discover/Break_Discover'



import './main.css'

const Main = () => {
  useEffect(() => {
    // Load Google Analytics Script
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-DYYJYQCZRJ";
    document.head.appendChild(script1);

    // Initialize Google Analytics
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-DYYJYQCZRJ');
    `;
    document.head.appendChild(script2);
  }, []);
  
  return (
    <div className='main'>
      <Banner />
      <Break_Signup />
      <Overview />
      <Break_Astral />
      <AvatarOverview />
      <Break_Commune />
      {/* <ChartOverview /> */}
      <TokenOverview />
      <Break_Discover />
      <AlchmizerOverview />
      <Roadmap />
      <Team />
      <FAQ />
    </div>
  )
}

export default Main
