import React from 'react'

import tokenoverview_image from '../../image/tokenoverview-image.webp'

import './tokenoverview.css'



const delay_gap = 300;


const TokenOverview = () => {

  function mouseOver(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.20)';
  }
  
  function mouseLeave(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.0)';
  }

  return (
    <div className='tokenOverview'>
      <div className='tokenOverviewLeftContainer'>
        <div className='tokenOverviewImageContainer'>
          <img data-aos="flip-left" src={tokenoverview_image} alt='' className='tokenOverviewImage' />
        </div>
      </div>
      <div className='tokenOverviewRightContainer'>
        <div className='tokenOverviewTextContainer'>
          <div className='tokenOverviewText tokenOverviewTitle tokenOverviewTextTop' data-aos="fade-down" data-aos-delay={1 * delay_gap}>Harvest from the Planets</div>
          <div  className='tokenOverviewText' data-aos="fade-down-left" data-aos-delay={2 * delay_gap}>• Collect Spirit, Matter, Essence and Substance tokens <span style={{ color: 'var(--color-main)' }}>daily</span></div>
          <div  className='tokenOverviewText' data-aos="fade-left" data-aos-delay={3 * delay_gap}>• Earn more by <span style={{ color: 'var(--color-main)' }}>aligning</span> your alchemy with your environment.</div>
          <div  className='tokenOverviewText' data-aos="fade-up-left" data-aos-delay={4 * delay_gap}>• Get <span style={{ color: 'var(--color-main)' }}>rewarded</span> for mastering astrology, astronomy, and alchemy.</div>
          <div className='tokenOverviewText  tokenOverviewTextBottom' data-aos="fade-up" data-aos-delay={5 * delay_gap} >• Synthesize tokens and elements to unlock new insights into your <span style={{ color: 'var(--color-main)' }}>astral self</span>.</div>
        </div>
      </div>
    </div>
  )
}

export default TokenOverview
