import React from 'react'

import Banner from '../../components/banner/Banner'
import About from '../../components/about/About'
import Overview from '../../components/overview/Overview'
import AvatarOverview from '../../components/avataroverview/AvatarOverview'
import TokenOverview from '../../components/tokenoverview/TokenOverview'
import AlchmizerOverview from '../../components/alchmizeroverview/AlchmizerOverview'
import Animation from '../../components/animation/Animation'
import Roadmap from '../../components/roadmap/Roadmap'
import Team from '../../components/team/Team'
import FAQ from '../../components/faq/FAQ'

import Break_Signup from '../../components/break_signup/Break_Signup'
import Break_Astral from '../../components/break_astral/Break_Astral'
import Break_Commune from '../../components/break_commune/Break_Commune'
import Break_Discover from '../../components/break_discover/Break_Discover'



import './main.css'

const Main = () => {
  return (
    <div className='main'>
      <Banner />
      <Break_Signup />
      <Overview />
      <Break_Astral />
      <AvatarOverview />
      <Break_Commune />
      <TokenOverview />
      <Break_Discover />
      <AlchmizerOverview />
      <Roadmap />
      <Team />
      <FAQ />
      {/* <Animation /> */}
    </div>
  )
}

export default Main
